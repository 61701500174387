import { Screen } from "app/components/screen";
import { Layout } from "../layout";
import { PageError } from "./page-error";

const PageNotFoundScreen = () => {
  return (
    <Screen sx={{ justifyContent: "center", alignItems: "center" }}>
      <PageError title="Page not found">
        The requested page was not found.
      </PageError>
    </Screen>
  );
};

const headerProps = {
  title: "Page not found",
  backButtonType: "none",
} as const;

PageNotFoundScreen.headerProps = headerProps;
PageNotFoundScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { PageNotFoundScreen };
